import React, { useEffect, useState } from "react";
import axios from "axios";
import s from "./Top.module.css"; // Предположим, что у вас есть стили для выделения
import { Avatar, List, Skeleton } from "antd";
import coin from "../../../assets/Header/coin.png";
import heart from "../../../assets/Header/heart.png";

const Description = ({ text, category }) => {
  return (
    <div className={s.description}>
      {text}{" "}
      {category === "like_count" ? <img src={heart} /> : <img src={coin} />}
    </div>
  );
};
const Title = ({ title }) => {
  return <div className={s.title}>{title}</div>;
};

const Top = ({ userId = 5 }) => {
  const [category, setCategory] = useState("like_count");
  const [leaders, setLeaders] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const categories = [
    { name: "Likes", value: "like_count" },
    { name: "Coins", value: "coin_count" },
  ];

  useEffect(() => {
    const fetchLeaders = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `https://blogerverse.com/api/leaders/${userId}/?sort_by=${category}`
        );
        setLeaders(response.data.top_10);
        setCurrentUser(response.data.current_user);
      } catch (error) {
        console.error("Ошибка при загрузке данных о лидерах:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchLeaders();
  }, [category, userId]);

  const getRankColor = (rank) => {
    if (rank === 1) return "gold";
    if (rank === 2) return "silver";
    if (rank === 3) return "#9e4f16";
    return "#660d72";
  };

  return (
    <div className={s.top}>
      <div className={s.categories}>
        {categories.map((cat) => (
          <div
            key={cat.value}
            className={
              category === cat.value ? s.categoryBtnActive : s.categoryBtn
            }
            onClick={() => setCategory(cat.value)}
          >
            {cat.name}
          </div>
        ))}
      </div>

      {loading ? (
        <Skeleton active />
      ) : (
        <div className={s.leaderboard}>
          <h2>Leaderboard ({category === "like_count" ? "Likes" : "Coins"})</h2>

          <List
            itemLayout="horizontal"
            dataSource={leaders}
            renderItem={(leader) => (
              <List.Item
                style={{
                  backgroundColor:
                    leader.user_id === userId ? "#731191" : "transparent",
                  borderLeft: `5px solid ${getRankColor(leader.rank)}`,
                  borderTop: "1px solid white",
                  
                }}
              >
                <List.Item.Meta
                  avatar={
                    <Avatar
                      style={{
                        backgroundColor: getRankColor(leader.rank),
                        color: "white",
                        fontWeight: "800",
                        border: "1px solid black",
                        marginLeft:"2px"
                      }}
                    >
                      {leader.rank}
                    </Avatar>
                  }
                  style={{ color: "white" }}
                  title={<Title title={`User ID: ${leader.user_id}`} />}
                  description={
                    <Description
                      text={`${
                        category === "like_count"
                          ? leader.like_count
                          : leader.coin_count
                      }`}
                      category={category}
                    />
                  }
                />
              </List.Item>
            )}
          />
          {currentUser && currentUser.rank > 10 && (
            <div className={s.currentUser}>
              <h3>Your Rank</h3>
              <List.Item
                style={{
                  backgroundColor: "#e6f7ff",
                  borderLeft: `5px solid ${getRankColor(currentUser.rank)}`,
                }}
              >
                <List.Item.Meta
                  avatar={
                    <Avatar
                      style={{
                        backgroundColor: getRankColor(currentUser.rank),
                      }}
                    >
                      {currentUser.rank}
                    </Avatar>
                  }
                  title={<Title title={`11User ID: ${currentUser.user_id}`} />}
                  description={`${
                    category === "like_count"
                      ? currentUser.like_count
                      : currentUser.coin_count
                  } ${category === "like_count" ? "Likes" : "Coins"}`}
                />
              </List.Item>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Top;
