import React from "react";
import s from "./OneQuest.module.css";
import videoP from "../../../assets/Buttons/video.png";
import {
  ChromeOutlined,
  YoutubeFilled,
  TrophyOutlined,
} from "@ant-design/icons";
import telegram from "../../../assets/telegram1.svg";
import axios from "axios";
import VideoPlayer from "../Video/Video";
import coin from "../../../assets/Header/coin.png";
import heart from "../../../assets/Header/heart.png";

const OneQuest = ({
  id,
  name,
  mission_type,
  types,
  upd_amount,
  quest_set,
  status,
  image,
}) => {
  const getQuestStyle = (status) => {
    switch (status) {
      case "reward_collected":
        return { border: "2px solid #66bd6d", opacity: 0.7 };
      case "reward_available":
        return {
          border: "2px solid #ffd700",
          animation: "pulse 1.5s infinite",
        };
      case "active":
        return { border: "2px solid #ff8c00" };
      default:
        return { border: "2px solid #444" };
    }
  };

  const handleQuestClick = () => {
    if (status !== "reward_collected") {
      axios
        .post(`https://blogerverse.com/api/quest/activate/`, {
          telegram_id: 5,
          quest_id: id,
        })
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.error("Error activating quest:", error);
        });
    }
  };

  return (
    <div
      onClick={handleQuestClick}
      style={getQuestStyle(status)}
      className={s.block}
    >
      <div className={s.images}>
        <img src={`https://blogerverse.com${image}`} alt={name} />
        <div className={s.typeSvg}>
          {mission_type === "subscribe_tg" && (
            <img src={telegram} alt="telegram" />
          )}
          {mission_type === "youtube" && (
            <YoutubeFilled style={{ color: "red" }} />
          )}
          {mission_type === "ingame_score_check" && (
            <TrophyOutlined style={{ color: "gold" }} />
          )}
        </div>
      </div>
      <div className={s.text}>
        <div className={s.name}>{name}</div>
        <div className={s.get}>
          {upd_amount}
          {types === "coin" ? (
            <img src={coin} alt="coin" />
          ) : (
            <img src={heart} alt="heart" />
          )}
        </div>
        <div className={s[status]}>
          {status === "reward_collected" && "Completed"}
          {status === "reward_available" && "Claim Reward!"}
          {status === "active" && "In Progress"}
          {status === "not_started" && "Start Quest"}
        </div>
      </div>
    </div>
  );
};

export default OneQuest;
