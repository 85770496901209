import Footer from "./Components/Footer/Footer";
import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider, useDispatch } from "react-redux";
import { store } from "./Redux/store";
import MainPage from "./Components/Main";
import Updates from "./Components/Updates/Updates";
import Wardrobe from "./Components/Main/Wardrobe";
import Profile from "./Components/Profile/Profile";
import Quests from "./Components/Quests/Quests";
import Shop from "./Components/Shop/Shop";
import { retrieveLaunchParams } from "@telegram-apps/sdk";
import setUser from "./Redux/user/userReducer";
import axios from "axios";
import s from "./App.module.css";
import GenerateVideo from "./Components/VideoGenerate/VideoUser";
import { useSelector } from "react-redux";
function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchLaunchParams = async () => {
      try {
        // Получаем данные initData из Telegram
        const { initData } = retrieveLaunchParams();
        // const startParam = initData.startParam; // Получаем параметр startParam
        // const startParam = null; // Получаем параметр startParam
        let userId = null;

        if (initData && initData.user) {
          const { id } = initData.user; // ID и имя пользователя из initData
          userId = id;
          // Парсим startParam
          // if (startParam) {
          //   const [key, value] = startParam.split("-");
          //   if (key === "referal_id") {
          //     // Запрос для пользователя с реферальным ID
          //     await axios.post("https://blogerverse.com/api/create-user/", {
          //       telegram_id: id,
          //       referal_id: value,
          //     });
          //     console.log("User created with referal_id:", value);
          //   } else if (key === "friend_id") {
          //     // Проверяем создание пользователя
          //     await axios.post("https://blogerverse.com/api/create-user/", {
          //       telegram_id: id,
          //     });

          //     // Запрос для добавления друга
          //     await axios.post("https://blogerverse.com/api/friendship/add-by-link/", {
          //       user_id: id,
          //       friend_id: value,
          //     });
          //     console.log("Friendship added with friend_id:", value);
          //   } else {
          //     console.warn("Unknown startParam:", startParam);
          //   }
          // } else {
          //   // Запрос, если startParam отсутствует
          //   await axios.post("https://blogerverse.com/api/create-user/", {
          //     telegram_id: id,
          //   });
          // }

          dispatch(setUser(id, "default"));
        } else {
          console.warn("Telegram launch parameters are missing or incomplete.");
        }
      } catch (error) {
        console.error("Error processing launch parameters:", error);
        // Фолбэк на дефолтного пользователя
        dispatch(setUser(5, "Default"));
      }
    };

    fetchLaunchParams();
  }, [dispatch]);
  const user = useSelector((state) => state.user);

  const [activeWard, setActiveWard] = useState(false);

  // const [loading, setLoading] = useState(true);

  return (
    <div className={s.app}>
      <BrowserRouter>
        {activeWard && <Wardrobe />}
        <Routes>
          <Route element={<MainPage activeWard={activeWard} />} path="/" />
          <Route element={<Quests />} path="/quests" />
          <Route element={<Updates />} path="/upgrade" />
          <Route element={<Profile />} path="/profile" />
          <Route element={<Shop />} path="/shop" />
          <Route element={<GenerateVideo />} path="/video" />
        </Routes>
        <Footer setActiveWard={setActiveWard} activeWard={activeWard} />
      </BrowserRouter>
    </div>
  );
}

const BricsApp = () => {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
};
export default BricsApp;
