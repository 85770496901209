import React from "react";
import VideoPopup from "../../Common/VideoPopup/VideoPopup";
import VideoPreview from "../../Common/VideoPreview/VideoPreview";
import {
  HeartOutlined,
  InfoCircleOutlined,
  CloseOutlined,
  HeartFilled,
} from "@ant-design/icons";
import s from "./UserManager.module.css";
import { useState, useEffect } from "react";

const UserManager = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [isFavorite, setIsFavorite] = useState(false);
  const [videos, setVideos] = useState({
    favorite: null,
    recent: [],
  });

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await fetch(
          "https://blogerverse.com/api/user-video/5"
        );
        const data = await response.json();

        setVideos({
          favorite: data.favorite_videos[0] || null,
          recent: data.non_favorite_videos || [],
        });
      } catch (error) {
        console.error("Ошибка при загрузке видео:", error);
      }
    };

    fetchVideos();
  }, []);

  const handleVideoClick = (video) => {
    setSelectedVideo(video);
    setIsPopupOpen(true);
  };

  const handleFavoriteClick = () => {
    setIsFavorite(!isFavorite);
    // Дополнительная логика
  };

  const buttons = [
    {
      icon: <CloseOutlined />,
      onClick: () => setIsPopupOpen(false),
      className: s.closeButton,
    },
    {
      icon: isFavorite ? <HeartFilled /> : <HeartOutlined />,
      onClick: handleFavoriteClick,
      className: s.favoriteButton,
    },
    {
      icon: <InfoCircleOutlined />,
      text: "Info",
      onClick: () => {
        /* логика */
      },
      className: s.infoButton,
    },
  ];

  return (
    <div className={s.container}>
      {/* Favorite Video */}
      <div className={s.favoriteSection}>
        <h3 className={s.sectionTitle}>Favorite Video</h3>
        {videos.favorite && (
          <div className={s.favoriteVideo}>
            <VideoPreview
              videoUrl={videos.favorite.video_file}
              previewUrl={null}
              onClick={() => handleVideoClick(videos.favorite)}
            />
          </div>
        )}
      </div>

      {/* Recent Videos */}
      <div className={s.recentSection}>
        <h3 className={s.sectionTitle}>Recent Videos</h3>
        <div className={s.recentVideos}>
          {videos.recent.map((video) => (
            <div key={video.id} className={s.recentVideo}>
              <VideoPreview
                videoUrl={video.video_file}
                previewUrl={null}
                onClick={() => handleVideoClick(video)}
              />
            </div>
          ))}
        </div>
      </div>

      {/* Video Popup */}
      <VideoPopup
        isOpen={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        videoUrl={selectedVideo?.video_file}
        buttons={buttons}
      />
    </div>
  );
};

export default UserManager;
