import React from "react";
import s from "./VideoPopup.module.css";
import {
  CloseOutlined,
  HeartOutlined,
  HeartFilled,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { Tooltip } from "antd";

const VideoPopup = ({
  isOpen,
  onClose,
  videoUrl,
  buttons = [], // массив кнопок с их конфигурацией
  description,
}) => {
  if (!isOpen) return null;

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div className={s.overlay} onClick={handleBackdropClick}>
      <div className={s.popup}>
        <div className={s.videoContainer}>
          <video
            className={s.video}
            controls
            autoPlay
            playsInline // важно для iOS
            controlsList="nodownload" // запрещаем скачивание
          >
            <source src={videoUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>

        <div className={s.buttonsContainer}>
          {buttons.map((button, index) => (
            <button
              key={index}
              onClick={button.onClick}
              className={`${s.button} ${button.className || ""}`}
              disabled={button.disabled}
            >
              {button.icon}
              {button.text}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VideoPopup;
