import React, { useState, useEffect } from "react";
import axios from "axios";
import s from "./Quests.module.css";
import { useSelector } from "react-redux";
import coin from "../../assets/Header/coin.png";
import heart from "../../assets/Header/heart.png";

const DailyBonus = ({ telegram_id = 5 }) => {
  const [streak, setStreak] = useState(0);
  const [maxStreak, setMaxStreak] = useState(0);
  const [lastClaimed, setLastClaimed] = useState(null);
  const [bonuses, setBonuses] = useState([]);
  const [error, setError] = useState("");
  const user = useSelector((state) => state.user);

  useEffect(() => {
    axios
      .get(`https://blogerverse.com/api/daily-bonus/${user.id}/`)
      .then((response) => {
        setStreak(response.data.current_streak);
        setLastClaimed(response.data.last_claimed);
        setMaxStreak(response.data.max_streak);
      })
      .catch((error) => {
        setError("Ошибка загрузки данных");
        console.error(error);
      });
  }, [user.id]);

  useEffect(() => {
    axios
      .get(`https://blogerverse.com/api/daily-bonus-all/`)
      .then((response) => {
        setBonuses(response.data);
      })
      .catch((error) => {
        setError("Ошибка загрузки данных");
        console.error(error);
      });
  }, [user.id, streak]);

  const handleClaimBonus = () => {
    axios
      .post(`https://blogerverse.com/api/daily-bonus/${user.id}/`)
      .then((response) => {
        setStreak(response.data.current_streak);
        setLastClaimed(response.data.last_claimed);
      })
      .catch((error) => {
        setError("Бонус уже был получен сегодня");
        console.error(error);
      });
  };
  const isToday = (date) => {
    if (!date) return false;
    const today = new Date();
    const givenDate = new Date(date);
    return (
      today.getDate() === givenDate.getDate() &&
      today.getMonth() === givenDate.getMonth() &&
      today.getFullYear() === givenDate.getFullYear()
    );
  };

  return (
    <div className={s.container}>
      <h2>Daily bonuses</h2>
      
      <div className={s.dailyList}>
        {bonuses.map((bonus, index) => {
          // Уже собранные (по максимальному стрику)
          const isCollected = index < maxStreak;

          // Можно собрать сегодня (текущий стрик равен максимальному,
          // сегодня еще не собирали, и это следующий бонус)
          const isClaimableToday =
            streak === maxStreak &&
            !isToday(lastClaimed) &&
            index === maxStreak;

          const blockClass = isCollected
            ? s.active
            : isClaimableToday
            ? `${s.active} ${s.claimable}`
            : s.inactive;

          return (
            <div
              key={index}
              className={blockClass}
              style={{
                cursor: isClaimableToday ? "pointer" : "default",
                color: isClaimableToday ? "yellow" : "white",
              }}
              onClick={isClaimableToday ? handleClaimBonus : undefined}
            >
              <div>Day: {bonus.day + 1}</div>
              <span>
                {bonus.currency_type === "coin" ? (
                  <img src={coin} alt="coin" />
                ) : (
                  <img src={heart} alt="heart" />
                )}
              </span>
              <span className={s.bonus}>{bonus.amount}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DailyBonus;
