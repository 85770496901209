import {
  SET_USER_DATA,
  SET_USER_DATA_ERROR,
  SET_WARDROBE,
  SET_WARDROBE_ITEM,
  SET_WARDROBE_ITEMS,
} from "../types";

const initialState = {
  username: "",
  id: null,
  eyes: null,
  hair: null,
  mouth: null,
  dummy: null,
  body: null,
  legs: null,
  shoes: null,
  eyesUrl: null,
  hairUrl: null,
  mouthUrl: null,
  dummyUrl: null,
  bodyUrl: null,
  legsUrl: null,
  shoesUrl: null,
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case SET_USER_DATA_ERROR:
      return {
        username: "error",
        id: "error",
      };
    case SET_WARDROBE:
      return {
        ...state,
        ...action.payload,
      };
    case SET_WARDROBE_ITEM:
      return {
        ...state,
        [action.payload.item]: action.payload.value, // Универсальное обновление по ключу
      };
    case SET_WARDROBE_ITEMS: // Новый кейс для записи картинок
      return {
        ...state,
        eyesUrl: action.payload.eyesUrl,
        hairUrl: action.payload.hairUrl,
        mouthUrl: action.payload.mouthUrl,
        dummyUrl: action.payload.dummyUrl,
        bodyUrl: action.payload.bodyUrl,
        legsUrl: action.payload.legsUrl,
        shoesUrl: action.payload.shoesUrl,
      };
    default:
      return state;
  }
};

export const setWardrobeUserData = ({
  eyes,
  hair,
  mouth,
  dummy,
  body,
  legs,
  shoes,
}) => ({
  type: SET_WARDROBE,
  payload: { eyes, hair, mouth, dummy, body, legs, shoes },
});
export const setWardrobeItem = (item, value) => ({
  type: SET_WARDROBE_ITEM,
  payload: { item, value },
});
const setUser = (id, username="default") => ({
  type: SET_USER_DATA,
  payload: { id, username },
});

export const setWardrobeItems = (items) => {
  const urls = {
    eyesUrl: null,
    hairUrl: null,
    mouthUrl: null,
    dummyUrl: null,
    bodyUrl: null,
    legsUrl: null,
    shoesUrl: null,
  };

  // Проходим по каждому элементу items и распределяем по соответствующему полю
  items.forEach((item) => {
    switch (item.type) {
      case "eyes":
        urls.eyesUrl = item.img;
        break;
      case "hair":
        urls.hairUrl = item.img;
        break;
      case "mouth":
        urls.mouthUrl = item.img;
        break;
      case "dummy":
        urls.dummyUrl = item.img;
        break;
      case "body":
        urls.bodyUrl = item.img;
        break;
      case "legs":
        urls.legsUrl = item.img;
        break;
      case "shoes":
        urls.shoesUrl = item.img;
        break;
      default:
        break;
    }
  });

  return {
    type: SET_WARDROBE_ITEMS,
    payload: urls,
  };
};

export default setUser;
