import React, { useState } from "react";
import DailyBonus from "./DailyQ.jsx";
import QuestComponent from "./QuestsList.jsx";
import PageStructure from "../PagesStructure/PageStructure.jsx";

const Quests = () => {
  const [category, setCategory] = useState("");
  const categories = [
    { name: "Quests", value: "" },
    { name: "Daily", value: "daily" },
    { name: "Special", value: "coin" },
  ];
  // Получаем все улучшения
  // useEffect(() => {
  //   axios
  //     .get(`http://127.0.0.1:8000/api/all-quests-user/5/ `)
  //     .then((response) => {
  //       setUpdates(response.data); // Установка данных о всех улучшениях
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching updates:", error);
  //       setLoading(false);
  //     });
  // }, [category]);

  // // Получаем улучшения, которые уже купил пользователь
  // useEffect(() => {
  //   axios
  //     .get(`https://blogerverse.com/api/user-updates/${1}`)
  //     .then((response) => {
  //       setUserUpdates(response.data);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching user updates:", error);
  //     });
  // }, []);

  // Функция, которая проверяет, было ли улучшение куплено пользователем

  return (
    
    <>
      <PageStructure
        categories={categories}
        category={category}
        setCategory={setCategory}
      >
        {category === "" && <QuestComponent />}
        {category === "daily" && <DailyBonus />}
      </PageStructure>
    </>
  );
};

export default Quests;
