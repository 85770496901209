import s from "./Footer.module.css";
import update from "../../assets/Buttons/upgrade.png";
import scene from "../../assets/Buttons/scene.png";
import profile from "../../assets/Buttons/Profile.png";
import backstage from "../../assets/Buttons/backstage.png";
import activities from "../../assets/Buttons/activities.png";
import { NavLink, useLocation } from "react-router-dom";

const navigationItems = [
  { path: "/", icon: scene, name: "Scene", isBackstage: false },
  { path: "/upgrade", icon: update, name: "Upgrade", isBackstage: false },
  { path: "/quests", icon: activities, name: "Activities", isBackstage: false },
  { path: "/", icon: backstage, name: "Backstage", isBackstage: true },
  { path: "/profile", icon: profile, name: "Profile", isBackstage: false },
];

const Footer = ({ setActiveWard, activeWard }) => {
  const location = useLocation();

  const handleClick = (isBackstage) => {
    try {
      if ("vibrate" in navigator) {
        navigator.vibrate([20,40,60]);
      } else {
        
      }
    } catch (error) {
      window.alert("Ошибка вибрации: " + error.message);
    }

    if (isBackstage) {
      setActiveWard(!activeWard);
    } else {
      setActiveWard(false);
    }
  };

  return (
    <div className={s.nav}>
      {navigationItems.map((item) => (
        <NavLink to={item.path} key={item.name}>
          <div
            onClick={() => handleClick(item.isBackstage)}
            className={
              item.isBackstage
                ? !activeWard
                  ? s.icon
                  : s.iconA
                : location.pathname !== item.path
                ? s.icon
                : s.iconA
            }
          >
            <img src={item.icon} alt={item.name} />
            <div className={s.name}>{item.name}</div>
          </div>
        </NavLink>
      ))}
    </div>
  );
};

export default Footer;
