import s from "./Main.module.css";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  setWardrobeUserData,
  setWardrobeItems,
} from "../../Redux/user/userReducer";
import shop from "../../assets/Buttons/shop.png";
import video from "../../assets/Buttons/video.png";
import friends from "../../assets/Buttons/friends1.png";
import star from "../../assets/Buttons/star.png";
import Header from "../Header";
import { NavLink } from "react-router-dom";
import Joyride from "react-joyride";
import { cloudStorage } from "@telegram-apps/sdk-react";

export const MainPage = ({ activeWard }) => {
  const user = useSelector((state) => state.user);
  useEffect(() => {}, [user]);
  return (
    <div className={!activeWard ? s.main : s.mainWiderobe}>
      <div>
        {!activeWard && <Header />}
        {/* {!activeProfile && !activeUpdates && !activeWard && <MainBtn />} */}
        <Person activeWard={activeWard} />
      </div>
    </div>
  );
};

const Person = ({ activeWard }) => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [timeLeft, setTimeLeft] = useState(10);
  const [run, setRun] = useState(false);
  // const [timeLeft2, setTimeLeft2] = useState(1000);
  // const [smallTimer, setSmallTimer] = useState(0);
  const [isRunning, setIsRunning] = useState(true);

  // Обратный отсчёт основного таймера
  useEffect(() => {
    if (timeLeft <= 0) {
      setIsRunning(false);
      // onComplete();
      return;
    }
    const timerId = setInterval(() => {
      setTimeLeft((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(timerId);
  }, [timeLeft]);

  const storageKey = "joyrideCompleted"; // Ключ для хранения состояния

  // Функция проверки состояния завершения обучения
  const checkJoyrideStatus = async () => {
    try {
      let completed = false;

      if (window.Telegram?.WebApp?.initData) {
        // Если в Telegram, используем cloudStorage
        completed = await cloudStorage.getCloudStorageItem(storageKey);
      } else {
        // Если в браузере, используем localStorage
        completed = localStorage.getItem(storageKey);
      }

      if (!completed) {
        setRun(true); // Запуск Joyride
      }
    } catch (error) {
      console.error("Ошибка проверки состояния обучения:", error);
    }
  };

  // Функция сохранения состояния завершения обучения
  const saveJoyrideStatus = async () => {
    try {
      if (window.Telegram?.WebApp?.initData) {
        // Сохраняем в Telegram cloudStorage
        await cloudStorage.setCloudStorageItem(storageKey, "true");
      } else {
        // Сохраняем в localStorage
        localStorage.setItem(storageKey, "true");
      }
    } catch (error) {
      console.error("Ошибка сохранения состояния обучения:", error);
    }
  };

  useEffect(() => {
    checkJoyrideStatus();
  }, []);

  const handleJoyrideCallback = async (data) => {
    const { status } = data;
    if (status === "finished") {
      await saveJoyrideStatus(); // Сохраняем завершение обучения
      setRun(false);
    }
  };
  const steps = [
    {
      target: ".first",
      content: "This is my awesome feature!",
      disableBeacon: true,
    },
    {
      target: ".second",
      content: "This another awesome feature!",
    },
  ];
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? `0${secs}` : secs}`;
  };
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const id = user.id || 5;
        const { data } = await axios.get(
          `https://blogerverse.com/api/usercustomlist?id=${id}`
        );

        dispatch(setWardrobeUserData(data.customisation));
        dispatch(setWardrobeItems(data.items));
      } catch (error) {
        console.error("Ошибка при загрузке данных пользователя:", error);
      }
    };

    fetchUserData();
  }, [user.id, dispatch]);

  return (
    <div>
      <Joyride
        continuous
        run={run}
        callback={handleJoyrideCallback}
        // scrollOffset={64}
        // scrollToFirstStep
        // showProgress
        // showSkipButton
        steps={steps}
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      />
      {!activeWard && (
        <div className={s.leftIcons}>
          <img
            src={video}
            alt="img"
            onClick={() => {
              axios.post(`https://blogerverse.com/api/video/${user.id}/`).then((res) => {
                console.log(res);
                setIsRunning(true);
                setTimeLeft(15);
              });
            }}
          />
          <div className={s.timer}>{isRunning ? formatTime(timeLeft) : ""}</div>
          <img src={star} alt="img" />
          <div className={s.timer}>
            {/* {isRunning ? formatTime(timeLeft2) : ""} */}
          </div>
        </div>
      )}
      <div className={s.person}>
        <img
          style={{
            zIndex: "1",
            opacity: `${activeWard ? 0.8 : 1}`,
          }}
          alt="img"
          src={`https://blogerverse.com${user.dummyUrl}`}
        />
        <img
          style={{
            zIndex: "2",
            opacity: `${activeWard ? 0.8 : 1}`,
          }}
          alt="img"
          src={`https://blogerverse.com${user.eyesUrl}`}
        />
        <img
          style={{
            zIndex: "3",
            opacity: `${activeWard ? 0.8 : 1}`,
          }}
          alt="img"
          src={`https://blogerverse.com${user.hairUrl}`}
        />
        <img
          style={{
            zIndex: "3",
            opacity: `${activeWard ? 0.8 : 1}`,
          }}
          alt="img"
          src={`https://blogerverse.com${user.bodyUrl}`}
        />
        <img
          style={{
            zIndex: "2",
            opacity: `${activeWard ? 0.8 : 1}`,
          }}
          alt="img"
          src={`https://blogerverse.com${user.mouthUrl}`}
        />
        <img
          style={{
            zIndex: "2",
            opacity: `${activeWard ? 0.85 : 1}`,
          }}
          alt="img"
          src={`https://blogerverse.com${user.legsUrl}`}
        />
        <img
          style={{
            zIndex: "1",
            opacity: `${activeWard ? 0.8 : 1}`,
          }}
          alt="img"
          src={`https://blogerverse.com${user.shoesUrl}`}
        />
      </div>
      {!activeWard && (
        <div className={s.rightIcons}>
          <NavLink to="/shop">
            <img className={"first"} src={shop} alt="img" />
          </NavLink>
          <NavLink to="/shop">
            <img className={"second"} src={friends} alt="img" />
          </NavLink>
        </div>
      )}
    </div>
  );
};

// const MainBtn = () => {
//   function registrateUser() {
//     axios.post("https://blogerverse.com/api/create-user/");
//   }
//   return (
//     <div style={{ margin: "0px auto", display: "flex" }}>
//       <button className={s.mainBtn} size="medium" style={{ margin: "0 auto" }}>
//         Создать видео
//       </button>
//     </div>
//   );
// };

export default MainPage;
