import React, { useState, useRef, useEffect } from "react";
import s from "./VideoPreview.module.css";
import { PlayCircleFilled } from "@ant-design/icons";

const VideoPreview = ({ videoUrl, onClick, previewUrl = null }) => {
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const videoRef = useRef(null);

  useEffect(() => {
    if (previewUrl) {
      // Если есть готовое превью с сервера, используем его
      setThumbnailUrl(previewUrl);
    } else {
      // Если нет, генерируем превью из первого кадра видео
      generateThumbnail();
    }
  }, [videoUrl, previewUrl]);

  const generateThumbnail = () => {
    const video = document.createElement("video");
    video.src = videoUrl;
    video.crossOrigin = "anonymous"; // Если видео с другого домена

    // Когда метаданные загружены, можно получить первый кадр
    video.onloadeddata = () => {
      video.currentTime = 0; // Устанавливаем на первый кадр
    };

    video.onseeked = () => {
      // Создаем canvas для получения изображения
      const canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

      try {
        const dataUrl = canvas.toDataURL("image/jpeg");
        setThumbnailUrl(dataUrl);
      } catch (e) {
        console.error("Error generating thumbnail:", e);
        // Если не удалось сгенерировать превью, можно использовать заглушку
        setThumbnailUrl("/path/to/fallback/image.jpg");
      }
    };
  };

  return (
    <div className={s.previewContainer} onClick={onClick}>
      {thumbnailUrl ? (
        <>
          <img src={thumbnailUrl} alt="Video preview" className={s.thumbnail} />
          {/* <div className={s.playButton}>
            <PlayCircleFilled />
          </div> */}
        </>
      ) : (
        <div className={s.loading}>Loading preview...</div>
      )}
    </div>
  );
};

export default VideoPreview;
