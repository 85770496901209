import s from "./Updates.module.css"; // Предположим, что у вас есть стили для выделения
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import TopMenu from "../TopMenu/TopMenu";
import image from "../../assets/Buttons/star.png";
import { Progress } from "antd";
import coin from "../../assets/Header/coin.png";
import heart from "../../assets/Header/heart.png";
import income_coin_click from "../../assets/Header/income_coin_click.png";
import income_like_click from "../../assets/Header/income_like_click.png";
import income_coin_sec from "../../assets/Header/income_coin_sec.png";
import income_like_sec from "../../assets/Header/income_like_sec.png";
import reduce_click from "../../assets/Header/reduce_click.png";

const Updates = () => {
  const [updates, setUpdates] = useState([]);
  const [userUpdates, setUserUpdates] = useState([]);
  // const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState("");

  const user = useSelector((state) => state.user);

  useEffect(() => {
    axios
      .get(
        `https://blogerverse.com/api/user-updates/${user.id}${
          category ? `?category=${category}` : ""
        }`
      ) // Получение данных об улучшениях
      .then((response) => {
        setUpdates(response.data); // Установка данных о всех улучшениях
      })
      .catch((error) => {
        console.error("Error fetching updates:", error);
      });
  }, [category]);

  const isUpdatePurchased = (updateId) => {
    return userUpdates.includes(updateId);
  };
  const setUpdate = (updateId) => {
    axios
      .post(`https://blogerverse.com/api/buy-upgrade/`, {
        user_id: 5,
        update_id: updateId,
      })
      .then((response) => {
        setUserUpdates([...userUpdates, updateId]);
      })
      .catch((error) => {
        console.error("Error buying update:", error);
      });
  };
  const formatNumber = (num) => {
    if (num >= 1_000_000_000_000_000)
      return `${(num / 1_000_000_000_000_000).toFixed(1)}Qa `;
    if (num >= 1_000_000_000_000)
      return `${(num / 1_000_000_000_000).toFixed(1)}T `;
    if (num >= 1_000_000_000) return `${(num / 1_000_000_000).toFixed(1)}B `;
    if (num >= 1_000_000) return `${(num / 1_000_000).toFixed(1)}M `;
    if (num >= 1_000) return `${(num / 1_000).toFixed(1)}K `;
    return num.toString();
  };
  return (
    <div
      style={{
        background:
          "linear-gradient(340deg, rgba(97, 50, 133, 1) 16%, rgba(23, 48, 119, 1) 43%, rgba(25, 25, 85, 1) 76%)",
        height: "100dvh",
        width: "100vw",
      }}
    >
      <TopMenu />
      <div className={s.category}>
        <div className={s.categoryMargin}>
          <div
            className={!category ? s.categoryBtnActive : s.categoryBtn}
            onClick={() => setCategory("")}
          >
            All
          </div>
          <div
            className={
              category === "collaborations"
                ? s.categoryBtnActive
                : s.categoryBtn
            }
            onClick={() => setCategory("collaborations")}
          >
            Collab
          </div>
          <div
            className={
              category === "equipment" ? s.categoryBtnActive : s.categoryBtn
            }
            onClick={() => setCategory("equipment")}
          >
            Equip
          </div>
          <div
            className={
              category === "content" ? s.categoryBtnActive : s.categoryBtn
            }
            onClick={() => setCategory("content")}
          >
            Content
          </div>
          <div
            className={
              category === "fans" ? s.categoryBtnActive : s.categoryBtn
            }
            onClick={() => setCategory("fans")}
          >
            Fans
          </div>
          <div
            className={
              category === "monetization" ? s.categoryBtnActive : s.categoryBtn
            }
            onClick={() => setCategory("monetization")}
          >
            Money
          </div>
        </div>
      </div>
      <div className={s.updatesListBlock}>
        <div>
          {updates.length > 5 && (
            <div className={s.updatesHeader}>{category}</div>
          )}
        </div>

        {updates.length > 5 &&
          updates?.map((update) => {
            return (
              <OneUpdate
                key={update.id}
                update={update}
                setUpdate={setUpdate}
                formatNumber={formatNumber}
                isUpdatePurchased={isUpdatePurchased}
              />
            );
          })}
        <div className={s.updatesHeader}>
          <div>{updates.collaborations && <>Collaborations</>}</div>
        </div>
        {updates.collaborations?.map((update) => {
          return (
            <OneUpdate
              key={update.id}
              update={update}
              setUpdate={setUpdate}
              formatNumber={formatNumber}
              isUpdatePurchased={isUpdatePurchased}
            />
          );
        })}
        <div className={s.updatesHeader}>
          <div>{updates.content && <>Content</>}</div>
        </div>
        {updates.content?.map((update) => {
          return (
            <OneUpdate
              key={update.id}
              update={update}
              setUpdate={setUpdate}
              formatNumber={formatNumber}
              isUpdatePurchased={isUpdatePurchased}
            />
          );
        })}
        <div className={s.updatesHeader}>
          <div>{updates.equipment && <>Equipment</>}</div>
        </div>
        {updates.equipment?.map((update) => {
          return (
            <OneUpdate
              key={update.id}
              update={update}
              setUpdate={setUpdate}
              formatNumber={formatNumber}
              isUpdatePurchased={isUpdatePurchased}
            />
          );
        })}
        <div className={s.updatesHeader}>
          <div>{updates.fans && <>Fans</>}</div>
        </div>
        {updates.fans?.map((update) => {
          return (
            <OneUpdate
              key={update.id}
              update={update}
              setUpdate={setUpdate}
              formatNumber={formatNumber}
              isUpdatePurchased={isUpdatePurchased}
            />
          );
        })}
        <div className={s.updatesHeader}>
          <div>{updates.monetization && <>Monetization</>}</div>
        </div>
        {updates.monetization?.map((update) => {
          return (
            <OneUpdate
              key={update.id}
              update={update}
              setUpdate={setUpdate}
              formatNumber={formatNumber}
              isUpdatePurchased={isUpdatePurchased}
            />
          );
        })}
      </div>
    </div>
  );
};

const OneUpdate = ({ update, setUpdate, formatNumber, isUpdatePurchased }) => {
  return (
    <div
      onClick={() => update.status === "available" && setUpdate(update.id)}
      className={s.updateBlock}
      data-status={update.status}
    >
      <div>
        <div className={s.topLeft}>
          <img src={`https://blogerverse.com${update.image}`} alt="img" />
          {update.level ? (
            <span className={s.percent}>
              <Progress
                type="circle"
                format={() => ""}
                size={25}
                percent={(update.level / update.max_level) * 100}
                status={
                  update.status === "max_level_reached" ? "success" : "active"
                }
                strokeWidth={6}
                strokeColor={{
                  "0%": "#108ee9",
                  "100%": "#87d068",
                }}
              />
              <span>
                {update.level} / {update.max_level}
              </span>
            </span>
          ) : null}
        </div>
        <div className={s.updateName}>{update.name}</div>
      </div>
      <div className={s.bonus}>
        <div className={s.bonusNum}>
          <span>{formatNumber(update.current_cost)}</span>
          {update.cost_type === "coin" ? (
            <img src={coin} alt="coin" />
          ) : (
            <img src={heart} alt="heart" />
          )}
        </div>
        <div className={s.bonusPay}>
          <span>{formatNumber(update.current_reward)}</span>
          {update.reward_type === "like" && <img src={heart} alt="like" />}
          {update.reward_type === "coin" && <img src={coin} alt="coin" />}
          {update.reward_type === "income_like_sec" && (
            <img src={income_like_sec} alt="like/sec" />
          )}
          {update.reward_type === "income_coin_sec" && (
            <img src={income_coin_sec} alt="coin/sec" />
          )}
          {update.reward_type === "income_like_click" && (
            <img src={income_like_click} alt="like/click" />
          )}
          {update.reward_type === "income_coin_click" && (
            <img src={income_coin_click} alt="coin/click" />
          )}
          {update.reward_type === "reduce_click" && (
            <img src={reduce_click} alt="reduce" />
          )}
        </div>
      </div>
    </div>
  );
};
export default Updates;
